import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const faqTextArray = [
  {
    i: 0,
    head: "What is Swissify?",
    para: (
      <span>
        <b>"Swissify Capital"</b>, a specialized high-performance cryptocurrency
        hedge fund based in Europe, aims to enhance the cryptocurrency holdings
        of our clients. We strive to achieve some of the highest returns in the
        market through advanced trading strategies. Our objective is to deliver
        a consistent monthly return rate of 3-5% for deposits in BTC, ETH, and
        USDT.
      </span>
    ),
  },
  {
    i: 1,
    head: "Who are our clients?",
    para: "Our clients are both high net-worth individuals and retailers, who have a long investment horizon. Our service is intended for investors who look for premium service and results with a high risk tolerance. The minimum deposit for every client is 5000 USD in any accepted crypto.",
  },
  {
    i: 2,
    head: "What is the fund's performance?",
    para: (
      <span>
        Our fund's current monthly return performance is <b>up to 5% monthly</b>{" "}
        (or yearly about <b>60% APY</b>) for BTC, ETH and USDT.
      </span>
    ),
  },
];
const faqTextArrayTwo = [
  {
    i: 3,
    head: "How to deposit?",
    para: (
      <span>
        Our platform completely smart contract based in Binance smart chain. The
        options for deposit are only BTC, ETH and USDT. The value of the deposit
        must be worth at least 5000 USD according to the current price of the
        asset. Client can connect their metamask wallet in our website and see
        their wallet balances and can deposit the funds in one click. Deposits
        and operations with other cryptocurrencies, cash, credit card or bank
        transfers are not possible.
      </span>
    ),
  },
  {
    i: 4,
    head: "How to withdraw?",
    para: "Our fund requires for assets to be consistently used by our strategies. Withdrawals can be requested only after the lock in period but customers can withdraw their accrued return anytime they want. Withdrawals are provided always in the same wallet with which the deposit has been created and it happens instantly.",
  },
  {
    i: 5,
    head: "What are the strategies which the fund utilizes?",
    para: "We employ a wide array of advanced trading strategies, encompassing spot markets, derivatives, arbitrage, and strategic coordination with institutional partners. Access to these strategies is restricted for security purposes and cannot be disclosed.",
  },
];

function Faq() {
  const matches = useMediaQuery("(max-width:750px)");
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel, index) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <Box mt={6} id="faq">
      <Container maxWidth="xl">
        <Typography
          variant="h3"
          sx={{
            color: "#4154f1",
            fontSize: { xs: "16px", md: "18px" },
            fontFamily: "Nunito",
            lineHeight: "16px",
            fontWeight: "700",
            textAlign: "center",
          }}
        >
          F.A.Q
        </Typography>
        <Typography
          variant="h1"
          sx={{
            color: "#012970",
            fontSize: { xs: "36px", md: "48px" },
            fontFamily: "Nunito",
            lineHeight: { xs: "50px", md: "60px" },
            fontWeight: "800",
            textAlign: "center",
            mt: 1,
          }}
        >
          How it works
        </Typography>
        <Grid container spacing={2} mt={4}>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            sx={{
              display: "flex",
              justifyContent: { xs: "center", md: "start" },
              alignItems: { xs: "center", md: "start" },
              flexDirection: "column",
            }}
          >
            <Stack
              gap={3}
              mt={3}
              sx={{
                width: { xs: "100%", md: "100%" },
              }}
            >
              {faqTextArray.map(({ head, para }, i) => (
                <Grid item spacing={2} key={head}>
                  <Accordion
                    key={`panel${i}`}
                    expanded={expanded === `panel${i}`}
                    onChange={handleChange(`panel${i}`)}
                    sx={{
                      background: "transparent",
                      borderBottom: "1px solid #939393",
                      boxShadow: "none",
                      borderRadius: "0px",
                      width: "100%",
                      m: "auto",
                      "&.MuiAccordion-root:before": {
                        height: "0px !important",
                      },
                      "&.MuiPaper-root": {
                        borderRadius: "0px !important",
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon
                          style={{
                            color: "#000000",
                            fontSize: "30px",
                          }}
                        />
                      }
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Box
                        fontSize={matches ? "16px" : "20px"}
                        fontWeight={800}
                        px={1}
                        py={1}
                        color={expanded === `panel${i}` ? "#4154f1" : "#444444"}
                        fontFamily="Nunito"
                      >
                        {head}
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box
                        pl={1}
                        py={1}
                        fontSize={{ xs: "14px", sm: "16px" }}
                        textAlign="left"
                        fontWeight="500"
                        lineHeight={"36px"}
                        color="#444444"
                        fontFamily="Nunito"
                      >
                        {para}
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              ))}
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            sx={{
              display: "flex",
              justifyContent: { xs: "center", md: "start" },
              alignItems: { xs: "center", md: "start" },
              flexDirection: "column",
            }}
          >
            <Stack
              gap={3}
              mt={3}
              sx={{
                width: { xs: "100%", md: "100%" },
              }}
            >
              {faqTextArrayTwo.map(({ head, para, i }) => (
                <Grid item spacing={2}>
                  <Accordion
                    key={`panel${i}`}
                    expanded={expanded === `panel${i}`}
                    onChange={handleChange(`panel${i}`)}
                    sx={{
                      background: "transparent",
                      borderBottom: "1px solid #939393",
                      boxShadow: "none",
                      borderRadius: "0px",
                      width: "100%",
                      m: "auto",
                      "&.MuiAccordion-root:before": {
                        height: "0px !important",
                      },
                      "&.MuiPaper-root": {
                        borderRadius: "0px !important",
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon
                          style={{
                            color: "#000000",
                            fontSize: "30px",
                          }}
                        />
                      }
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Box
                        fontSize={matches ? "18px" : "20px"}
                        fontWeight={800}
                        px={1}
                        py={1}
                        color={expanded === `panel${i}` ? "#4154f1" : "#444444"}
                        fontFamily="Nunito"
                      >
                        {head}
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box
                        pl={1}
                        py={1}
                        fontSize={{ xs: "14px", sm: "16px" }}
                        textAlign="left"
                        fontWeight="500"
                        lineHeight={"36px"}
                        color="#444444"
                        fontFamily="Nunito"
                      >
                        {para}
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              ))}
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Faq;
