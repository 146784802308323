import { Alert, Box, Snackbar, TextField, Typography } from "@mui/material";
import { Button } from "@mui/material";

export function ToastNotify({ alertState, setAlertState }) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={alertState.open}
      autoHideDuration={10000}
      key={"top center"}
      onClose={() => setAlertState({ ...alertState, open: false })}
    >
      <Alert
        onClose={() => setAlertState({ ...alertState, open: false })}
        severity={alertState.severity}
      >
        {alertState.message}
      </Alert>
    </Snackbar>
  );
}

export function StyledButton({ shadowStatus, children, ...props }) {
  return (
    <>
      <Button
        {...props}
        disabled={props.disable}
        sx={{
          color: "#ffffff",
          background: "#4154f1",
          fontSize: "18px",
          textTransform: "capitalize",
          fontFamily: "Nunito",
          fontWeight: "700",
          borderRadius: "6px",
          width: props.width,
          boxShadow: shadowStatus
            ? "none"
            : "0px 5px 30px rgba(65, 84, 241, 0.4)",
          "&.Mui-disabled": {
            color: "#979EA7",
          },

          "&:hover": {
            background: "#4154f1",
            ".MuiButton-endIcon": {
              marginLeft: "12px",
              transition: "0.3s",
            },
          },
        }}
      >
        {children}
      </Button>
    </>
  );
}

export function StyledText({ children, ...props }) {
  return (
    <>
      <Box
        {...props}
        sx={{
          color: "#013289",
          fontSize: "18px",
          fontFamily: "Nunito",
          fontWeight: "700",
          mr: props.mr,
          "&:hover": {
            color: "#4154f1",
          },
        }}
      >
        {children}
      </Box>
    </>
  );
}
export function StyledTitle({ children, ...props }) {
  return (
    <>
      <Box
        {...props}
        sx={{
          color: "#000000",
          fontSize: "40px",
          //   fontFamily: "Josefin Sans",
          fontWeight: "700",
          mr: props.mr,
        }}
      >
        {children}
      </Box>
    </>
  );
}

export const StyledInput = ({ ...props }) => {
  return (
    <TextField
      variant="outlined"
      {...props}
      sx={{
        background: "#ffffff",
        // borderRadius: "20px",
        // border: "0.56px solid #000000",
        // boxShadow:
        //   "0px 5.913842678070068px 14.78460693359375px 0px #00000026,0px 1.4046640396118164px 1.75583016872406px 0px #FFFFFF inset",
        width: "100%",
        "& .MuiOutlinedInput-root": {
          // borderRadius: "12px",
          // "& fieldset": {
          //   border: "none",
          // },
          // "&:hover fieldset": {
          //   border: "none",
          // },
          // "&.Mui-focused fieldset": {
          //   border: "none",
          // },
        },

        input: {
          "&::placeholder": {
            color: "#444444 !important",
            opacity: 1,
          },
          // padding: { xs: "16px 16px", sm: "12px 18px" },//
          color: "#444444",
          fontSize: "16px",
          fontWeight: "500",
          fontFamily: "Nunito",
        },
        textArea: {
          "&::placeholder": {
            color: "#444444 !important",
            opacity: 1,
          },
          // padding: { xs: "16px 16px", sm: "12px 18px" },//
          color: "#444444",
          fontSize: "16px",
          fontWeight: "500",
          fontFamily: "Nunito",
        },
      }}
    />
  );
};

export function StyledHeading({ children, ...props }) {
  return (
    <Typography
      {...props}
      variant="h3"
      textAlign="center"
      fontFamily="Nunito"
      fontWeight="700"
      color="#4154f1"
      mb={4}
    >
      {children}
    </Typography>
  );
}

export function StyledHeadingTwo({ children, ...props }) {
  return (
    <Typography
      {...props}
      variant="h5"
      textAlign="center"
      fontFamily="Nunito"
      fontWeight="700"
      color="#4154f1"
      mt={{ xs: 1, sm: -1 }}
    >
      {children}
    </Typography>
  );
}

export function StyledTypography({ children, ...props }) {
  return (
    <Typography
      {...props}
      variant="subtitle1"
      textAlign="center"
      fontFamily="Nunito"
      fontWeight="500"
      fontSize="19px"
    >
      {children}
    </Typography>
  );
}

export function StyledTypographyTwo({ children, ...props }) {
  return (
    <Typography {...props} variant="body1" fontFamily="Nunito" fontWeight={600}>
      {children}
    </Typography>
  );
}
export function StyledTypographyThree({ children, ...props }) {
  return (
    <Typography
      {...props}
      variant="subtitle2"
      fontFamily="Nunito"
      // fontWeight={600}
    >
      {children}
    </Typography>
  );
}

export function StyledTypographyFour({ children, ...props }) {
  return (
    <Typography
      {...props}
      variant="subtitle1"
      fontFamily="Nunito"
      fontWeight="500"
      fontSize="18px"
      sx={{ color: "#ffff" }}
    >
      {children}
    </Typography>
  );
}
