import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import {
  StyledButton,
  StyledInput,
} from "../components/SmallComponents/AppComponents";

function Contact() {
  return (
    <Box mt={6} id="contact">
      <Container maxWidth="xl">
        <Typography
          variant="h3"
          sx={{
            color: "#4154f1",
            fontSize: { xs: "16px", md: "18px" },
            fontFamily: "Nunito",
            lineHeight: "16px",
            fontWeight: "700",
            textAlign: "center",
          }}
        >
          CONTACT
        </Typography>
        {/* <Typography
          variant="h1"
          sx={{
            color: "#012970",
            fontSize: { xs: "36px", md: "48px" },
            fontFamily: "Nunito",
            lineHeight: { xs: "50px", md: "60px" },
            fontWeight: "800",
            textAlign: "center",
            mt: 1,
          }}
        >
          Talk to us!
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: "#444444",
            fontSize: { xs: "16px", sm: "18px" },
            fontFamily: "Nunito",
            textAlign: "center",
            fontWeight: "500",
            mt: 3,
          }}
        >
          Our user dashboard is coming soon! <br />
          Until then please email us at{" "}
          <a href="mailto:info.swissify@proton.me">
            info.swissify@proton.me
          </a>{" "}
          for deposit or withdrawal requests or if you would like to join the
          fund.
        </Typography> */}
        <Grid container spacing={4} justifyContent={"center"} mt={4}>
          <Grid item xs={12} md={3}>
            <Stack
              gap={2}
              sx={{
                backgroundColor: "#fafbff",
                padding: "30px",
              }}
            >
              <MailOutlineIcon
                sx={{
                  color: "#4154f1",
                  fontSize: "60px",
                }}
              />

              <Typography
                variant="h3"
                sx={{
                  color: "#012970",
                  fontSize: "24px",
                  fontFamily: "Nunito",
                  lineHeight: "16px",
                  fontWeight: "800",
                }}
              >
                Contact
              </Typography>
              <Typography
                variant="h3"
                sx={{
                  color: "#444444",
                  fontSize: { xs: "16px", sm: "16px" },
                  fontFamily: "Nunito",
                  fontWeight: "500",
                  lineHeight: "26px",
                }}
              >
                <a
                  href="mailto:info@swissify.io"
                  style={{
                    color: "#4154f1",
                    textDecoration: "none",
                  }}
                >
                  info@swissify.io
                </a>
                <br />
                <a
                  href="mailto:support@swissify.io"
                  style={{
                    color: "#4154f1",
                    textDecoration: "none",
                  }}
                >
                  support@swissify.io
                </a>
              </Typography>
            </Stack>
          </Grid>
          {/* <Grid item xs={12} md={3}>
            <Stack
              gap={2}
              sx={{
                backgroundColor: "#fafbff",
                padding: "30px",
              }}
            >
              <AccessTimeIcon
                sx={{
                  color: "#4154f1",
                  fontSize: "60px",
                }}
              />

              <Typography
                variant="h3"
                sx={{
                  color: "#012970",
                  fontSize: "24px",
                  fontFamily: "Nunito",
                  lineHeight: "16px",
                  fontWeight: "800",
                }}
              >
                Fund schedule
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "#444444",
                  fontSize: { xs: "16px", sm: "18px" },
                  fontFamily: "Nunito",
                  lineHeight: "26px",
                  fontWeight: "600",
                }}
              >
                Quarterly withdrawal request windows:
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "#444444",
                  fontSize: { xs: "14px", sm: "16px" },
                  fontFamily: "Nunito",

                  fontWeight: "400",
                }}
              >
                1 - 7 January 2024 <br /> 1 - 7 April 2024 <br />1 - 7 July 2024{" "}
                <br />1 - 7 October 2024
              </Typography>
            </Stack>
          </Grid> */}
          <Grid item xs={12} md={8}>
            <Stack
              gap={2}
              sx={{
                backgroundColor: "#fafbff",
                padding: "30px",
              }}
            >
              <Grid container spacing={4} justifyContent={"center"}>
                <Grid item xs={6}>
                  <Box>
                    <StyledInput type="text" placeholder="Your Name" />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box>
                    <StyledInput type="email" placeholder="Your Email" />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box>
                    <StyledInput type="text" placeholder="Subject" />
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Box>
                    <StyledInput
                      type="text"
                      placeholder="Message"
                      multiline
                      rows={4}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Stack alignItems={"center"}>
                    <StyledButton width="180px">Send Message</StyledButton>
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Contact;
