import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { btcImg, ethImg, usdtImg } from "../components/SmallComponents/Images";

const valuesArray = [
  {
    img: btcImg,
    title: "BTC",
    para: (
      <span>
        <b>Bitcoin</b> is the gold standard in digital investment with a limited
        supply of 21 million coins.
      </span>
    ),
    value: "0.1 BTC",
  },
  {
    img: ethImg,
    title: "BNB",
    para: (
      <span>
        <b>BNB</b> is the foundation of the smart contract based ecosystem of
        the future
      </span>
    ),
    value: "10.00 BNB",
  },
  {
    img: usdtImg,
    title: "USDT",
    para: (
      <span>
        <b>USDT</b> is chosen by investors who wish to shield their portfolio
        from price swings
      </span>
    ),
    value: "$5,000",
  },
];

function Values() {
  return (
    <Box id="values" mt={6}>
      <Container maxWidth="xl">
        <Typography
          variant="h3"
          sx={{
            color: "#4154f1",
            fontSize: { xs: "16px", md: "18px" },
            fontFamily: "Nunito",
            lineHeight: "16px",
            fontWeight: "700",
            textAlign: "center",
          }}
        >
          INVESTMENT FUND
        </Typography>
        <Typography
          variant="h1"
          sx={{
            color: "#012970",
            fontSize: { xs: "36px", md: "48px" },
            fontFamily: "Nunito",
            lineHeight: { xs: "50px", md: "60px" },
            fontWeight: "800",
            textAlign: "center",
            mt: 1,
          }}
        >
          Let us grow your digital assets
        </Typography>
        <Grid container spacing={4} justifyContent={"center"} my={4}>
          {valuesArray?.map(({ img, title, para, value }, i) => (
            <Grid item xs={12} md={4} key={title} p={{ xs: 0, md: 4 }}>
              <Stack
                gap={2}
                alignItems={"center"}
                sx={{
                  padding: {
                    xs: "30px 30px 30px 30px",
                    md: "30px 30px 10px 30px",
                  },
                  height: { xs: "inherit", md: "100%" },
                  boxShadow: "0px 0 5px rgba(1, 41, 112, 0.08)",
                  transition: "0.3s",

                  "&:hover": {
                    boxShadow: "0px 5px 30px rgba(1, 41, 112, 0.15)",
                    transform: "scale(1.0)",
                    transition: "0.5s",

                    ".imgScale": {
                      transition: "0.5s",
                      transform: "scale(0.9)",
                    },
                  },
                }}
              >
                <Box
                  className="imgScale"
                  component={"img"}
                  alt=""
                  src={img}
                  width={title === "BNB" ? "65%" : "80%"}
                  sx={{
                    transition: "0.3s",
                    transform: "scale(1.1)",
                    mt: title === "BNB" ? 3 : 0,
                  }}
                />

                <Typography
                  variant="h3"
                  sx={{
                    color: "#012970",
                    fontSize: "24px",
                    fontFamily: "Nunito",
                    lineHeight: "16px",
                    fontWeight: "800",
                    textAlign: "center",
                    mt: title === "BNB" ? 6 : 3,
                  }}
                >
                  {title}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: "#444444",
                    fontSize: "18px",
                    fontFamily: "Nunito",
                    fontWeight: "500",
                    textAlign: "center",
                    mt: 1,
                  }}
                >
                  {para}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: "#444444",
                    fontSize: "18px",
                    fontFamily: "Nunito",
                    fontWeight: "500",
                    textAlign: "center",
                    mt: 3,
                  }}
                >
                  <b>Minimum investment:</b> {value}
                </Typography>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

export default Values;
