import { useWeb3Modal } from "@web3modal/wagmi/react";
import { StyledButton } from "./AppComponents";
import { useContext } from "react";
import { AppContext } from "../../utils";

export const ExampleButton = ({ width }) => {
  const { account } = useContext(AppContext);
  const { open } = useWeb3Modal();

  return (
    <StyledButton
      shadowStatus={true}
      width={width}
      onClick={async () => await open()}
    >
      {account ? account.slice(0, 4) + "..." + account.slice(-4) : "Connect"}
    </StyledButton>
  );
};
