import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import { aboutImg } from "../components/SmallComponents/Images";
import { StyledButton } from "../components/SmallComponents/AppComponents";
import EastIcon from "@mui/icons-material/East";

function About() {
  return (
    <Box mt={6} id="about">
      <Container maxWidth="xl">
        <Grid container spacing={0} justifyContent={"center"}>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                backgroundColor: "#f6f9ff",
                p: 4,
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  color: "#4154f1",
                  fontSize: { xs: "16px", md: "18px" },
                  fontFamily: "Nunito",
                  lineHeight: "16px",
                  fontWeight: "700",
                }}
              >
                Who We Are
              </Typography>
              <Typography
                variant="h1"
                sx={{
                  color: "#012970",
                  fontSize: { xs: "26px", md: "32px" },
                  fontFamily: "Nunito",
                  lineHeight: "40px",
                  fontWeight: "700",
                  mt: 2,
                }}
              >
                Utilizing the time-tested Swiss wealth management techniques for
                crypto assets
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  color: "#444444",
                  fontSize: { xs: "16px", sm: "18px" },
                  fontFamily: "Nunito",

                  fontWeight: "500",
                  mt: 2,
                }}
              >
                <b>Swissify Capital</b> , headquartered in Zurich, is a
                cutting-edge digital asset management fund designed for
                investors seeking to optimize their crypto returns. Our platform
                provides top-tier wealth management solutions tailored to
                Bitcoin (BTC), Binance (BNB), and Tether (USDT). Safeguarding
                all assets through regulated custody ensures maximum security
                and peace of mind.
                {/* Launched in Zurich, <b>Swissify Capital</b> is a high-performance
                digital asset management fund for investors who want to maximize
                their crypto earnings. We offer premium wealth management
                services for Bitcoin (BTC), Ethereum (ETH) and Tether (USDT).
                All of the assets are hosted on hardware wallets ({" "}
                <a
                  href="https://www.investopedia.com/terms/c/cold-storage.asp"
                  title="Cold wallet storage for cryptcurrencies"
                >
                  "cold storage"
                </a>
                ¹) and our servers in{" "}
                <a
                  href="https://en.wikipedia.org/wiki/Switzerland"
                  title="Switzerland"
                >
                  Switzerland
                </a>
                ². */}
              </Typography>
              <Box
                mt={3}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: { xs: "center", md: "flex-start" },
                }}
              >
                <StyledButton width="200px" endIcon={<EastIcon />}>
                  Read More
                </StyledButton>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box>
              <Box component={"img"} alt="" src={aboutImg} width="100%" />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default About;
