import { Route, Routes } from "react-router-dom";
import {
  About,
  Contact,
  Dashboard,
  Faq,
  HeroSection,
  NewsLetter,
  Values,
} from "./pages";
import Footer from "./components/Footer";
function App() {
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <HeroSection />
              <About />
              <Values />
              <Faq />
              <Contact />
              <NewsLetter />
            </>
          }
        />
        <Route path="/dashboard" element={<Dashboard />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
