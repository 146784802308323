import {
  Box,
  Container,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { footerBg, logo } from "./SmallComponents/Images";
import { FaFacebook } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";

const socialArray = [
  {
    icon: (
      <FaTwitter
        style={{
          fontSize: "28px",
        }}
      />
    ),
    link: "#",
  },
  {
    icon: (
      <FaFacebook
        style={{
          fontSize: "28px",
        }}
      />
    ),
    link: "#",
  },
  {
    icon: (
      <FaInstagram
        style={{
          fontSize: "28px",
        }}
      />
    ),
    link: "#",
  },
  {
    icon: (
      <FaLinkedin
        style={{
          fontSize: "28px",
        }}
      />
    ),
    link: "#",
  },
];

function Footer() {
  return (
    <>
      <Box
        sx={{
          background: `white url(${footerBg}) no-repeat right top`,
          backgroundSize: "contain",
          borderTop: "1px solid #e1ecff",
          borderBottom: "1px solid #e1ecff",
          py: 6,
        }}
      >
        <Container maxWidth="xl">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              {" "}
              <Stack
                sx={{
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Box component={"img"} alt="" src={logo} width={"140px"} />
                {/* <Typography
                  variant="h1"
                  sx={{
                    color: "#012970",
                    fontSize: { xs: "26px", sm: "30px" },
                    fontFamily: "Nunito",
                    lineHeight: "16px",
                    fontWeight: "700",
                    letterSpacing: "1",
                    textTransform: "capitalize",
                  }}
                >
                  SWISSIFY
                </Typography> */}
              </Stack>
              <Typography
                variant="body1"
                sx={{
                  color: "#444444",
                  fontSize: { xs: "16px", sm: "18px" },
                  fontFamily: "Nunito",
                  fontWeight: "400",
                  width: "100%",
                  mt: 3,
                }}
              >
                <b>Swissify Capital </b> is a high-performance digital asset
                management fund based in Europe for investors who want to
                maximize their crypto earnings.
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  // gap: 2,
                  my: 1,
                }}
              >
                {socialArray.map(({ icon, link }, index) => (
                  <IconButton
                    disableRipple={true}
                    href={link}
                    target="_blank"
                    key={index * Math.random() + 1}
                    sx={{
                      color: "rgba(1, 41, 112, 0.5)",
                      "&:hover": {
                        transition: "0.5s",
                        color: "#012970",
                      },
                    }}
                  >
                    {icon}
                  </IconButton>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box
        sx={{
          background: "#f6f9ff",
          borderTop: "1px solid #e1ecff",
          py: 6,
        }}
      >
        <Container maxWidth="xl">
          <Typography
            variant="body1"
            sx={{
              color: "#012970",
              fontSize: { xs: "16px", sm: "18px" },
              fontFamily: "Nunito",
              fontWeight: "400",
              textAlign: "center",
            }}
          >
            © Copyright <strong>Swissify Capital</strong> . All rights reserved
          </Typography>

          <Typography
            variant="body1"
            sx={{
              color: "#444444",
              fontSize: { xs: "12px", sm: "14px" },
              fontFamily: "Nunito",
              fontWeight: "400",
              mt: 3,
            }}
          >
            <b>DISCLAIMER:</b> Nothing in this website is or should be
            considered as an investment, legal, financial or tax advice or
            recommendation. The services offered by our platform are considered
            “high-risk, high-return” type of strategies and are intended only
            for investors with considerable risk tolerance. Cryptocurrencies are
            highly volatile assets with limited and varying regulation across
            different jurisdictions. You should always consult with your local
            licensed financial, tax, legal or investment advisor before making
            decisions. Your cryptocurrency deposits are not covered by any bank
            insurance and loss of principal is possible. Past results do not
            guarantee future results.
          </Typography>
        </Container>
      </Box>
    </>
  );
}

export default Footer;
