import tokenAbi from "./tokenAbi.json";
import stakingAbi from "./stakingAbi.json";
import bnbStakingAbi from "./bnbStakingAbi.json";
import btcStakingAbi from "./btcStakingAbi.json";
import { readContract, writeContract } from "wagmi/actions";
import { waitForTransaction } from "@wagmi/core";

export const tokenReadFunction = async (address, functionName, args) => {
  const data = await readContract({
    address: address,
    abi: tokenAbi,
    functionName,
    args,
  });
  return data;
};

export const stakingReadFunction = async (
  address,
  currency,
  functionName,
  args
) => {
  const data = await readContract({
    address: address,
    abi:
      currency === "BNB"
        ? bnbStakingAbi
        : currency === "BTC"
        ? btcStakingAbi
        : stakingAbi,
    functionName,
    args,
  });
  return data;
};

/// write functions
export const tokenWriteFunction = async (address, functionName, args) => {
  const { hash } = await writeContract({
    address: address,
    abi: tokenAbi,
    functionName,
    args,
  });
  const receipt = await waitForTransaction({ hash });
  return receipt;
};

export const usdtWriteFunction = async (address, functionName, args) => {
  const { hash } = await writeContract({
    address: address,
    abi: stakingAbi,
    functionName,
    args,
  });
  const receipt = await waitForTransaction({ hash });
  return receipt;
};

export const stakingWriteFunction = async (
  address,
  currency,
  functionName,
  args,
  value
) => {
  const { hash } = await writeContract({
    address: address,
    abi:
      currency === "BNB"
        ? bnbStakingAbi
        : currency === "BTC"
        ? btcStakingAbi
        : stakingAbi,
    functionName,
    args,
    value,
  });
  const receipt = await waitForTransaction({ hash });
  return receipt;
};
