export const tokenAddress = "0x2101eA58C52b3D038671BbCB9FAf071d3EB8Cd2a";

/// usdt Addresses
const stakingUsdtAddress = "0x9DC9FCA8F041ed1F710AF6CE44178667f9a9479A";
const usdtTokenAddress = "0x55d398326f99059fF775485246999027B3197955";

// bnb addresses

const stakingBnbAddress = "0xaEcAc855cCc1143325C2fddDDDa81d4B240AEA55";
// const bnbTokenAddress = "0x4C0EAB8B6F159f1d172B3F7C290Eb2Cc3f764c82";

// btc addresses

const stakingBtcAddress = "0x7d87Acf9c72b1eE1Cc190762CaF04C561A293F6b";
const BtcTokenAddress = "0x0a27A4ED211b117098Dc1a2d9e5e3F5B871Ce956";

export const getContractSwitch = (buyWith) => {
  switch (buyWith) {
    case "BNB":
      return {
        stakingAddress: stakingBnbAddress,
        // tokenAddress: bnbTokenAddress,
      };
    case "BTC":
      return {
        stakingAddress: stakingBtcAddress,
        tokenAddress: BtcTokenAddress,
      };

    default:
      return {
        stakingAddress: stakingUsdtAddress,
        tokenAddress: usdtTokenAddress,
      };
  }
};
