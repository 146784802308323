import React, { useContext } from "react";
import {
  Container,
  useMediaQuery,
  SwipeableDrawer,
  Button,
  List,
  ListItemText,
  Box,
  Paper,
  Stack,
  Typography,
  ListItemButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import MenuIcon from "@mui/icons-material/Menu";
import clsx from "clsx";
import { logo } from "./SmallComponents/Images";

import { AppContext } from "../utils";
import { StyledText } from "./SmallComponents/AppComponents";
import { ExampleButton } from "./SmallComponents/StyledWalletButton";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
    alignItems: "center",
  },
  paper: {
    background: "#FFFFFF !important",
  },
  hover: {
    "&:hover": {
      color: "#FFB800",
    },
  },
});

const navArray = [
  {
    text: "Home",
    link: "/#hero",
  },
  {
    text: "Who we are",
    link: "/#about",
  },
  {
    text: "Investment fund",
    link: "/#values",
  },
  {
    text: "How it works",
    link: "/#faq",
  },
  {
    text: "Contact",
    link: "/#contact",
  },
  {
    text: "Dashboard",
    link: "/dashboard",
  },
];
export default function Header() {
  const matchesTwo = useMediaQuery("(max-width:1250px)");
  const { account } = useContext(AppContext);
  const classes = useStyles();
  const [state, setState] = React.useState({
    left: false,
  });
  const matches1 = useMediaQuery("(max-width:1279px)");

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          gap: 1,
          justifyContent: "center",
          mt: 4,
          mb: 2,
        }}
      >
        <Box component={"img"} alt="" src={logo} width={"140px"} />
        {/* <Typography
          variant="h1"
          sx={{
            color: "#012970",
            fontSize: "26px",
            fontFamily: "Nunito",
            lineHeight: "16px",
            fontWeight: "700",
            letterSpacing: "1",
            textTransform: "capitalize",
          }}
        >
          SWISSIFY
        </Typography> */}
      </Stack>
      <List>
        {navArray?.map(({ text, link }, i) => (
          <ListItemButton key={text}>
            {+i === 5 ? (
              account && (
                <Link
                  to={link}
                  style={{ textDecoration: "none", margin: "auto" }}
                >
                  <ListItemText
                    sx={{
                      textAlign: "center",
                      textDecoration: "none",
                      cursor: "pointer",
                      ".MuiTypography-root": {
                        fontFamily: "Nunito",
                        fontSize: "20px",
                        fontWeight: "700",
                      },
                      color: "#013289",
                      "&:hover": {
                        color: "#4154f1",
                      },
                    }}
                    primary={text}
                  />
                </Link>
              )
            ) : (
              <HashLink
                smooth
                to={link}
                style={{ textDecoration: "none", margin: "auto" }}
              >
                <ListItemText
                  sx={{
                    textAlign: "center",
                    textDecoration: "none",
                    cursor: "pointer",
                    ".MuiTypography-root": {
                      fontFamily: "Nunito",
                      fontSize: "20px",
                      fontWeight: "700",
                    },
                    color: "#013289",
                    "&:hover": {
                      color: "#4154f1",
                    },
                  }}
                  primary={text}
                />
              </HashLink>
            )}
          </ListItemButton>
        ))}
      </List>
      <Box mt={2} display="flex" justifyContent="center">
        {/* <StyledButton width="160px">Start earning</StyledButton> */}
        <ExampleButton width={"160px"} />
      </Box>
    </div>
  );
  return (
    <>
      <Box
        sx={{
          background: "transparent",
        }}
        width="100%"
        py={2}
      >
        <Container maxWidth="xl">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Link
              to="/"
              style={{
                textDecoration: "none",
              }}
            >
              <Box component={"img"} alt="" src={logo} width={"120px"} />
            </Link>
            {/* <Stack
              sx={{
                flexDirection: "row",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Box component={"img"} alt="" src={logo} width={"40px"} />
              <Typography
                variant="h1"
                sx={{
                  color: "#012970",
                  fontSize: { xs: "26px", sm: "30px" },
                  fontFamily: "Nunito",
                  lineHeight: "16px",
                  fontWeight: "700",
                  letterSpacing: "1",
                  textTransform: "capitalize",
                }}
              >
                SWISSIFY
              </Typography>
            </Stack> */}

            <Box
              display="flex"
              justifyContent={matches1 ? "end" : "space-between"}
              alignItems="center"
            >
              {!matchesTwo ? (
                <>
                  {navArray?.map(({ text, link }, i) => (
                    <>
                      {+i === 5 ? (
                        account && (
                          <Link to={link} style={{ textDecoration: "none" }}>
                            <StyledText key={text} mr={6}>
                              {text}
                            </StyledText>
                          </Link>
                        )
                      ) : (
                        <HashLink
                          key={text}
                          smooth
                          to={link}
                          style={{ textDecoration: "none" }}
                        >
                          <StyledText key={text} mr={6}>
                            {text}
                          </StyledText>
                        </HashLink>
                      )}
                    </>
                  ))}

                  {/* <StyledButton width="160px">Start earning</StyledButton> */}
                  <ExampleButton width={"160px"} />
                </>
              ) : (
                <>
                  {["left"].map((anchor) => (
                    <React.Fragment key={anchor}>
                      <Button
                        onClick={toggleDrawer(anchor, true)}
                        style={{ zIndex: 1 }}
                      >
                        <MenuIcon
                          style={{
                            fontSize: "38px",
                            cursor: "pointer",
                            color: "#013289",
                          }}
                        />
                      </Button>
                      <Paper>
                        <SwipeableDrawer
                          classes={{ paper: classes.paper }}
                          anchor={anchor}
                          open={state[anchor]}
                          onClose={toggleDrawer(anchor, false)}
                          onOpen={toggleDrawer(anchor, true)}
                        >
                          {list(anchor)}
                        </SwipeableDrawer>
                      </Paper>
                    </React.Fragment>
                  ))}
                </>
              )}
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
}
