import { useContext, useEffect, useState } from "react";
import {
  Box,
  Container,
  Grid,
  InputAdornment,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  tableCellClasses,
  useMediaQuery,
} from "@mui/material";
import { useNetwork } from "wagmi";
import {
  StyledButton,
  StyledInput,
  StyledTypographyTwo,
  ToastNotify,
} from "../components/SmallComponents/AppComponents";
import { FaCalendarDays } from "react-icons/fa6";
import { formatUnits, parseUnits } from "viem";
import Loading from "../components/SmallComponents/loading";
import { AppContext } from "../utils";
import NetworkSwitch from "../NetworkSwitch";
import moment from "moment";
import Header from "../components/Header";
import { SiTether } from "react-icons/si";
import { RiBnbFill } from "react-icons/ri";
import { FaBtc } from "react-icons/fa";
import {
  stakingReadFunction,
  stakingWriteFunction,
  tokenReadFunction,
  tokenWriteFunction,
} from "../ConnectivityAssets/hooks";
import { getContractSwitch } from "../ConnectivityAssets/environment";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    padding: "13px",
    fontSize: "14px",
    fontWeight: "700",
    textAlign: "center",
    fontFamily: "Nunito",
    border: "none",
  },
  [`&.${tableCellClasses.body}`]: {
    padding: "13px",
    fontSize: "14px",
    textAlign: "center",
    fontFamily: "Nunito",
    border: "none",
  },
}));

export default function Dashboard() {
  const mobileMatches = useMediaQuery("(max-width:650px)");
  let currentTime = moment().format("X");
  const [buyWith, setBuyWith] = useState("USDT");
  const [stakeDays, setStakeDays] = useState(0);
  const { chain } = useNetwork();
  // let account = "0xd9DeeC8c001EA71cBE632A15C7ad88b0a535Af26";
  const { account } = useContext(AppContext);
  const [openNetworkSwitch, setOpenNetworkSwitch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [amountToStake, setAmountToStake] = useState("");
  // const [userDepositedUSDT, setuserDepositedUSDT] = useState(0);
  // const [userDepositedBNB, setuserDepositedBNB] = useState(0);
  // const [userDepositedBTC, setuserDepositedBTC] = useState(0);
  // const [userDepositedBNBInUSDT, setuserDepositedBNBInUSDT] = useState(0);
  // const [userDepositedBTCInUSDT, setuserDepositedBTCInUSDT] = useState(0);
  // const [userWithdrawnUSDT, setuserWithdrawnUSDT] = useState(0);
  // const [userWithdrawnBNB, setuserWithdrawnBNB] = useState(0);
  // const [userWithdrawnBTC, setuserWithdrawnBTC] = useState(0);
  // const [userWithdrawnBNBInUSDT, setuserWithdrawnBNBInUSDT] = useState(0);
  // const [userWithdrawnBTCInUSDT, setuserWithdrawnBTCInUSDT] = useState(0);
  // const [userClaimableUSDT, setuserClaimableUSDT] = useState(0);
  // const [userClaimableBNB, setuserClaimableBNB] = useState(0);
  // const [userClaimableBTC, setuserClaimableBTC] = useState(0);
  // const [userClaimableBNBInUSDT, setuserClaimableBNBInUSDT] = useState(0);
  // const [userClaimableBTCInUSDT, setuserClaimableBTCInUSDT] = useState(0);

  const [totalDepositByUser, settotalDepositByUser] = useState(0);
  const [totalWithdrawnByUser, settotalWithdrawnByUser] = useState(0);
  const [totalClaimableByUser, settotalClaimableByUser] = useState(0);

  const [values, setValues] = useState({
    totalInvested: 0,
    totalWithdrawn: 0,
    totalClaimed: 0,
  });
  const [userTableData, setUserTableData] = useState([]);
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });

  const showAlert = (message, severity = "error") => {
    setAlertState({
      open: true,
      message,
      severity,
    });
  };

  const toLocalFormat = (val) => {
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  useEffect(() => {
    if (account && chain && chain?.id !== 56) {
      setOpenNetworkSwitch(true);
    }
  }, [chain, account]);

  const getValues = async () => {
    try {
      const contractAddress = getContractSwitch(buyWith);
      const totalStakedAmountContract = await stakingReadFunction(
        contractAddress?.stakingAddress,
        buyWith,
        "totalStakedAmount"
      );
      const totalWithdrawanContract = await stakingReadFunction(
        contractAddress?.stakingAddress,
        buyWith,
        "totalWithdrawan"
      );

      const totalUserCountContract = await stakingReadFunction(
        contractAddress?.stakingAddress,
        buyWith,
        "totalUsersCount"
      );

      let totalClaimed = 0;

      for (let i = 0; i < +totalUserCountContract?.toString(); i++) {
        const addressCountContract = await stakingReadFunction(
          contractAddress?.stakingAddress,
          buyWith,
          "UsersAddresses",
          [i]
        );
        const totalClaimedContract = await stakingReadFunction(
          contractAddress?.stakingAddress,
          buyWith,
          "claimableRewardForAllStakes",
          [addressCountContract]
        );
        totalClaimed += +formatUnits(totalClaimedContract?.toString(), 18);
      }
      setValues({
        totalInvested: parseFloat(
          formatUnits(totalStakedAmountContract?.toString(), 18)
        )?.toFixed(0),
        totalWithdrawn: parseFloat(
          formatUnits(totalWithdrawanContract?.toString(), 18)
        )?.toFixed(0),
        totalClaimed: parseFloat(totalClaimed)?.toFixed(0),
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buyWith]);

  const userValues = async () => {
    try {
      // const buyWithLength = ["USDT", "BNB", "BTC"];
      const buyWithLength = ["USDT"];

      let dt = [];
      let withdrawn = 0;
      let claimable = 0;
      let deposited = 0;
      for (let currency of buyWithLength) {
        let currencyLatestPrice = 1;
        const contractAddress = getContractSwitch(currency);
        if (currency === "BNB" || currency === "BTC") {
          const latestPriceContract = await stakingReadFunction(
            contractAddress?.stakingAddress,
            currency,
            "getLatestPrice"
          );
          currencyLatestPrice = +formatUnits(
            latestPriceContract?.toString(),
            8
          );
        }
        const totalStakeUserContract = await stakingReadFunction(
          contractAddress?.stakingAddress,
          currency,
          "getUserInfo",
          [account]
        );

        if (totalStakeUserContract[0]) {
          for (let i = 0; i < +totalStakeUserContract[1]?.toString(); i++) {
            const userData = await stakingReadFunction(
              contractAddress?.stakingAddress,
              currency,
              "getUserStakeInfo",
              [account, i]
            );
            const claimableRewardUser = await stakingReadFunction(
              contractAddress?.stakingAddress,
              currency,
              "calculateReward",
              [account, i]
            );
            const obj = {
              currencyName: currency,
              arrayIndex: i,
              planIndex: +userData[0]?.toString(),
              amount: parseFloat(
                formatUnits(userData[1]?.toString(), 18) * currencyLatestPrice
              )?.toFixed(2),
              startTime: +userData[2]?.toString(),
              endTime: +userData[4]?.toString(),
              reward: parseFloat(
                formatUnits(claimableRewardUser?.toString(), 18) *
                  currencyLatestPrice
              )?.toFixed(2),
              rewardClaimed: parseFloat(
                formatUnits(userData[5]?.toString(), 18) * currencyLatestPrice
              )?.toFixed(2),
              withDrawnStatus: userData[6],
            };
            dt.push(obj);

            //// USER'S OVERALL INFO /////
            let usdtValueDeposit =
              +formatUnits(userData[1]?.toString(), 18) * currencyLatestPrice;
            // let usdtValueWithdrawn =
            // +formatUnits(userData[5]?.toString(), 18) * currencyLatestPrice;
            let usdtValueClaimable =
              +formatUnits(userData[5]?.toString(), 18) * currencyLatestPrice;
            deposited += +parseFloat(usdtValueDeposit)?.toFixed(2);
            if (userData[6]) {
              withdrawn +=
                +parseFloat(usdtValueDeposit)?.toFixed(2) +
                +parseFloat(usdtValueClaimable)?.toFixed(2);
            } else if (!userData[6] && +userData[5]?.toString() > 0) {
              withdrawn += +parseFloat(usdtValueClaimable)?.toFixed(2);
            } else if (
              !userData[6] &&
              +currentTime > +userData[4]?.toString()
            ) {
              claimable +=
                +parseFloat(usdtValueDeposit)?.toFixed(2) +
                +parseFloat(
                  formatUnits(claimableRewardUser?.toString(), 18)
                )?.toFixed(2);
            } else {
              claimable += +parseFloat(
                formatUnits(claimableRewardUser?.toString(), 18)
              )?.toFixed(2);
            }
          }
          // if (currency === "USDT") {
          //   setuserDepositedUSDT(deposited);
          //   setuserClaimableUSDT(claimable);
          //   setuserWithdrawnUSDT(withdrawn);
          // } else if (currency === "BNB") {
          //   setuserDepositedBNB(deposited);
          //   setuserClaimableBNB(claimable);
          //   setuserWithdrawnBNB(withdrawn);
          //   setuserDepositedBNBInUSDT(+deposited * currencyLatestPrice);
          //   setuserWithdrawnBNBInUSDT(withdrawn * currencyLatestPrice);
          //   setuserClaimableBNBInUSDT(claimable * currencyLatestPrice);
          // } else {
          //   setuserDepositedBTC(deposited);
          //   setuserClaimableBTC(claimable);
          //   setuserWithdrawnBTC(withdrawn);
          //   setuserDepositedBTCInUSDT(+deposited * currencyLatestPrice);
          //   setuserWithdrawnBTCInUSDT(withdrawn * currencyLatestPrice);
          //   setuserClaimableBTCInUSDT(claimable * currencyLatestPrice);
          // }
        }
      }
      settotalDepositByUser(deposited);
      settotalWithdrawnByUser(withdrawn);
      settotalClaimableByUser(claimable);
      setUserTableData(dt);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (account) {
      userValues();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  const stakeHandler = async () => {
    if (!account) {
      return showAlert("Error! Please connect Total wallet.");
    }
    if (!amountToStake || +amountToStake <= 0) {
      return showAlert("Please enter amount");
    }
    try {
      const contractAddress = getContractSwitch(buyWith);
      setLoading(true);

      if (buyWith === "BNB") {
        await stakingWriteFunction(
          contractAddress?.stakingAddress,
          buyWith,
          "stake",
          [stakeDays],
          parseUnits(amountToStake?.toString(), 18)
        );
      } else {
        await tokenWriteFunction(contractAddress?.tokenAddress, "approve", [
          contractAddress?.stakingAddress,
          parseUnits(amountToStake?.toString(), 18),
        ]);
        await stakingWriteFunction(
          contractAddress?.stakingAddress,
          buyWith,
          "stake",
          [parseUnits(amountToStake?.toString(), 18), stakeDays]
        );
      }
      setAmountToStake("");
      getValues();
      userValues();
      setLoading(false);
      showAlert("Staked successfully!", "success");
    } catch (e) {
      setLoading(false);
      showAlert(e?.shortMessage);
    }
  };

  const handleInputChange = (event) => {
    const input = event.target.value;
    const newValue = input?.replace(/[^0-9.]/g, "");
    setAmountToStake(newValue);
  };

  const claimHandler = async (currencyName, index) => {
    try {
      if (!account) {
        return showAlert("Error! Please connect Total wallet.");
      }
      const contractAddress = getContractSwitch(currencyName);
      setLoading(true);
      await stakingWriteFunction(
        contractAddress?.stakingAddress,
        currencyName,
        "claim",
        [index?.toString()]
      );
      getValues();
      userValues();
      setLoading(false);
      showAlert("You have successfully Claimed Tokens!!!", "success");
    } catch (e) {
      setLoading(false);
      showAlert(e?.shortMessage);
    }
  };

  const withDrawnHandler = async (currencyName, index) => {
    try {
      if (!account) {
        return showAlert("Error! Please connect Total wallet.");
      }
      const contractAddress = getContractSwitch(currencyName);
      setLoading(true);
      await stakingWriteFunction(
        contractAddress?.stakingAddress,
        currencyName,
        "withdraw",
        [index?.toString()]
      );
      getValues();
      userValues();
      setLoading(false);
      showAlert("You have successfully Withdrawn Tokens!", "success");
    } catch (e) {
      setLoading(false);
      showAlert(e?.shortMessage);
    }
  };

  return (
    <>
      <Header />
      <NetworkSwitch open={openNetworkSwitch} setOpen={setOpenNetworkSwitch} />
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />
      <Loading loading={loading} />
      <Container maxWidth="xl">
        <Grid
          container
          boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
          mt={2}
        >
          {["Your Invested", "Your Claimable", "Your Withdrawn"].map(
            (text, i) => {
              return (
                <Grid key={i} item xs={12} md={4}>
                  <Box
                    p={2}
                    borderRight={{ xs: "none", md: "3px solid #F9F8FD" }}
                    borderBottom={{ xs: "3px solid #F9F8FD", md: "none" }}
                  >
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <StyledTypographyTwo fontSize="18px" color="#191919">
                        {text}
                      </StyledTypographyTwo>
                      <StyledTypographyTwo
                        fontSize={{ xs: "20px", md: "25px" }}
                        color={
                          i === 1 ? "#6dc574" : i === 2 ? "#192B7C" : "#191919"
                        }
                      >
                        $
                        {i === 0
                          ? toLocalFormat(totalDepositByUser)
                          : i === 1
                          ? toLocalFormat(totalClaimableByUser)
                          : toLocalFormat(totalWithdrawnByUser)}
                      </StyledTypographyTwo>
                    </Stack>

                    {/* <Stack
                      mt={4}
                      direction="row"
                      justifyContent="space-between"
                    >
                      <StyledTypographyThree color="#BFC0C4">
                        {buyWith}
                      </StyledTypographyThree>
                      <StyledTypographyThree
                        color={
                          i === 1 ? "#6dc574" : i === 2 ? "#192B7C" : "#191919"
                        }
                      >
                        {i === 0
                          ? parseFloat(totalDepositByUser).toFixed(2)
                          : i === 1
                          ? parseFloat(totalClaimableByUser).toFixed(2)
                          : parseFloat(totalWithdrawnByUser).toFixed(2)}
                      </StyledTypographyThree>
                    </Stack> */}
                  </Box>
                </Grid>
              );
            }
          )}
        </Grid>

        <Grid container spacing={3} mt={0}>
          <Grid item xs={12} md={12}>
            <Box
              boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
              height="100%"
              pb={1}
            >
              <StyledTypographyTwo
                p={2}
                fontWeight="600"
                color="#191919"
                borderBottom="3px solid #F9F8FD"
              >
                Deposit
              </StyledTypographyTwo>
              <Stack mt={1}>
                <Container maxWidth="sm">
                  <Stack
                    my={1}
                    sx={{
                      mx: 1,
                      borderRadius: "12px",
                      flexDirection: "row",
                      background: "#F6F9FF",
                    }}
                  >
                    <Stack
                      sx={{
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      {[
                        {
                          text: "USDT",
                          // img: usdtImg,
                        },
                        {
                          text: "BNB",
                          // img: ethImg,
                        },

                        {
                          text: "BTC",
                          // img: btcImg,
                        },
                      ].map(({ text }) => (
                        <Stack
                          onClick={() =>
                            //  setBuyWith(text)
                            setBuyWith("USDT")
                          }
                          key={text}
                          sx={{
                            cursor: "pointer",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: 1,
                            width: "50%",
                            py: { xs: 1, sm: 2 },
                            borderRadius: "12px",
                            background:
                              buyWith === text ? "#4a56bd" : "transparent",
                          }}
                        >
                          {/* {icon} */}
                          {text === "USDT" ? (
                            <SiTether
                              style={{
                                fontSize: "20px",
                                color: buyWith === text ? "#ffffff" : "#444444",
                              }}
                            />
                          ) : text === "BTC" ? (
                            <FaBtc
                              style={{
                                fontSize: "20px",
                                color: buyWith === text ? "#ffffff" : "#444444",
                              }}
                            />
                          ) : (
                            <RiBnbFill
                              style={{
                                fontSize: "20px",
                                color: buyWith === text ? "#ffffff" : "#444444",
                              }}
                            />
                          )}
                          {/* <Box
                            component={"img"}
                            alt=""
                            src={img}
                            width="26px"
                          /> */}
                          <Typography
                            variant="subtitle2"
                            sx={{
                              color: buyWith === text ? "#ffffff" : "#444444",
                              fontFamily: "Nunito",
                              fontSize: { xs: "10px", sm: "14px" },
                              fontWeight: "800",
                            }}
                          >
                            {text}
                          </Typography>
                        </Stack>
                      ))}
                    </Stack>
                  </Stack>
                  <Stack
                    mt={2}
                    sx={{
                      mx: 1,
                      borderRadius: "12px",
                      flexDirection: "row",
                      background: "#F6F9FF",
                    }}
                  >
                    <Stack
                      sx={{
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      {[
                        {
                          text: 0,
                          value: (
                            <span>30d {mobileMatches && <br />} (APY 36%)</span>
                          ),
                        },
                        {
                          text: 1,
                          value: (
                            <span>90d {mobileMatches && <br />} (APY 48%)</span>
                          ),
                        },
                        {
                          text: 2,
                          value: (
                            <span>
                              180d {mobileMatches && <br />} (APY 60%)
                            </span>
                          ),
                        },
                      ].map(({ text, value }) => (
                        <Stack
                          onClick={() => setStakeDays(text)}
                          key={text}
                          sx={{
                            cursor: "pointer",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: 1,
                            width: "50%",
                            py: { xs: 1, sm: 2 },
                            borderRadius: "12px",
                            background:
                              stakeDays === text ? "#4a56bd" : "transparent",
                          }}
                        >
                          <FaCalendarDays
                            style={{
                              fontSize: "16px",
                              color: stakeDays === text ? "#ffffff" : "#444444",
                            }}
                          />
                          <Typography
                            variant="subtitle2"
                            sx={{
                              color: stakeDays === text ? "#ffffff" : "#444444",
                              fontFamily: "Nunito",
                              fontSize: { xs: "10px", sm: "14px" },
                              fontWeight: "800",
                              textAlign: mobileMatches ? "center" : "left",
                            }}
                          >
                            {value}
                          </Typography>
                        </Stack>
                      ))}
                    </Stack>
                  </Stack>
                </Container>
              </Stack>
              <Box px={2} pt={2.5}>
                <StyledTypographyTwo color="#7B7C82">
                  Enter your deposit amount in {buyWith}:
                </StyledTypographyTwo>
                <Box>
                  <StyledInput
                    type="text"
                    value={amountToStake}
                    onChange={handleInputChange}
                    placeholder="0.00"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Box sx={{ mr: -1 }}>
                            <StyledButton
                              width="100px"
                              shadowStatus={true}
                              onClick={stakeHandler}
                            >
                              submit
                            </StyledButton>
                          </Box>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Box
          my={3}
          boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
        >
          <StyledTypographyTwo
            p={2}
            borderBottom="3px solid #F9F8FD"
            color="#191919"
          >
            Deposits history
          </StyledTypographyTwo>

          <Box p={2}>
            <TableContainer
              sx={{
                boxShadow: "none",
                background: "none",
              }}
            >
              <Table sx={{ minWidth: "700px" }}>
                <TableHead
                  sx={{
                    background: "#F6F6F6",
                    fontFamily: "Quicksand",
                  }}
                >
                  <TableRow>
                    <StyledTableCell>Deposit Amount</StyledTableCell>
                    <StyledTableCell>Claimable Reward</StyledTableCell>
                    <StyledTableCell>Claimed Reward</StyledTableCell>
                    <StyledTableCell>Lock Until</StyledTableCell>
                    <StyledTableCell>Claim</StyledTableCell>
                    <StyledTableCell>Withdraw</StyledTableCell>
                  </TableRow>
                </TableHead>
                {userTableData.length > 0 ? (
                  <TableBody>
                    {userTableData.map(
                      (
                        {
                          currencyName,
                          planIndex,
                          amount,
                          startTime,
                          endTime,
                          reward,
                          rewardClaimed,
                          withDrawnStatus,
                          arrayIndex,
                        },
                        i
                      ) => {
                        return (
                          <TableRow key={i}>
                            <StyledTableCell>${amount}</StyledTableCell>
                            <StyledTableCell>${reward}</StyledTableCell>
                            <StyledTableCell>${rewardClaimed}</StyledTableCell>
                            <StyledTableCell>
                              {moment.unix(endTime).format("LLL")}
                            </StyledTableCell>
                            <StyledTableCell>
                              <StyledButton
                                disable={withDrawnStatus}
                                width="120px"
                                onClick={() =>
                                  claimHandler(currencyName, arrayIndex)
                                }
                              >
                                Claim
                              </StyledButton>
                            </StyledTableCell>
                            <StyledTableCell>
                              <StyledButton
                                disable={
                                  currentTime > endTime && !withDrawnStatus
                                    ? false
                                    : true
                                }
                                width="120px"
                                onClick={() =>
                                  withDrawnHandler(currencyName, arrayIndex)
                                }
                              >
                                Withdraw
                              </StyledButton>
                            </StyledTableCell>
                          </TableRow>
                        );
                      }
                    )}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell
                        colSpan={5}
                        style={{
                          border: "none",
                          textAlign: "center",
                          paddingTop: "25px",
                          fontFamily: "Nunito",
                        }}
                      >
                        No Data
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Container>
    </>
  );
}
