import {
  Box,
  Container,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import {
  StyledButton,
  StyledInput,
} from "../components/SmallComponents/AppComponents";

function NewsLetter() {
  return (
    <Box
      mt={6}
      sx={{
        background: "#f6f9ff",
        borderTop: "1px solid #e1ecff",
        py: 6,
      }}
    >
      <Container maxWidth="xl">
        <Typography
          variant="h1"
          sx={{
            color: "#012970",
            fontSize: { xs: "36px", md: "48px" },
            fontFamily: "Nunito",
            lineHeight: { xs: "50px", md: "60px" },
            fontWeight: "800",
            textAlign: "center",
            mt: 3,
          }}
        >
          Our Newsletter
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: "#444444",
            fontSize: { xs: "16px", sm: "18px" },
            fontFamily: "Nunito",
            textAlign: "center",
            fontWeight: "500",
            // mt: 3,
          }}
        >
          Join our newsletter to stay updated with us!
        </Typography>
        <Stack my={3}>
          <Container maxWidth="md">
            <StyledInput
              type="text"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Box mr={-1}>
                      <StyledButton width="130px" shadowStatus={true}>
                        Subcribe
                      </StyledButton>
                    </Box>
                  </InputAdornment>
                ),
              }}
            />
          </Container>
        </Stack>
      </Container>
    </Box>
  );
}

export default NewsLetter;
