import React from "react";
import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import Header from "../components/Header";
import { heroBg, heroOne } from "../components/SmallComponents/Images";
import SquareIcon from "@mui/icons-material/Square";
import { StyledButton } from "../components/SmallComponents/AppComponents";
import EastIcon from "@mui/icons-material/East";

const liArray = [
  <span>
    <b>Minimum deposit:</b> 5000 USD in coin value
  </span>,
  <span>
    <b>Withdrawal requests:</b> Min 30 days lock in
  </span>,
  <span>
    <b>Regulated custody</b> and <b>Swiss expertise</b>
  </span>,
];

function HeroSection() {
  return (
    <Box
      id="hero"
      sx={{
        background: `url(${heroBg}) top center no-repeat`,
        backgroundSize: "cover",
        backgroundAttachment: "fixed",
      }}
    >
      <Header />
      <Container maxWidth="xl">
        <Grid container spacing={0} justifyContent={"center"}>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Stack gap={2}>
              <Typography
                variant="h1"
                sx={{
                  color: "#012970",
                  fontSize: { xs: "36px", md: "48px" },
                  fontFamily: "Nunito",
                  lineHeight: { xs: "50px", md: "60px" },
                  fontWeight: "800",
                  textAlign: { xs: "center", md: "left" },
                  mt: 3,
                }}
              >
                Make your crypto grow daily
              </Typography>
              <Typography
                variant="h2"
                sx={{
                  color: "#444444",
                  fontSize: { xs: "22px", sm: "26px" },
                  fontFamily: "Nunito",
                  fontWeight: "600",
                  lineHeight: "40px",
                  textAlign: { xs: "center", md: "left" },
                }}
              >
                Earn <b>up to 5% monthly</b> on your <b>BTC, BNB</b> and{" "}
                <b>USDT</b>.
              </Typography>
              {liArray?.map((text) => (
                <Stack
                  flexDirection={"row"}
                  alignItems={"center"}
                  gap={1}
                  key={text}
                >
                  <SquareIcon sx={{ color: "#444444", fontSize: "8px" }} />
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#444444",
                      fontSize: { xs: "16px", sm: "18px" },
                      fontFamily: "Nunito",
                      fontWeight: "400",
                      width: "100%",
                      textAlign: { xs: "center", md: "left" },
                    }}
                  >
                    {text}
                  </Typography>
                </Stack>
              ))}
              <Box
                mt={1}
                sx={{
                  display: "flex",
                  justifyContent: { xs: "center", md: "flex-start" },
                  alignItems: "center",
                }}
              >
                <StyledButton width="200px" endIcon={<EastIcon />}>
                  Get Started
                </StyledButton>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box>
              <Box component={"img"} alt="" src={heroOne} width={"100%"} />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default HeroSection;
